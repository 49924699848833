<template>
  <DataTable :options="options" />
</template>

<script>
import { TemplateService } from '@tutti/services';

export default {
  data() {
    return {
      options: {
        addNew: true,
        headers: [
          { text: 'Name', value: 'name' },
          {
            text: 'Partial',
            value: 'isPartial',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
        actions: [
          {
            text: 'Enable',
            body: { isActive: true },
          },
          {
            text: 'Disable',
            body: { isActive: false },
          },
          {
            text: 'Delete',
            body: { isDeleted: true },
          },
        ],
        action: {
          hideView: true,
          actions: [
            {
              tooltip: 'Send test email',
              icon: 'mdi-send',
              click: this.sendEmail,
            },
          ],
        },
      },
    };
  },

  methods: {
    async sendEmail(item) {
      const response = await TemplateService.sendEmail(item._id);
      if (response) {
        // do something
      }
    },
  },
};
</script>
